const zipCodes = [
  84664,
  84663,
  84660,
  84655,
  84653,
  84651,
  84633,
  84606,
  84605,
  84604,
  84603,
  84602,
  84601,
  84414,
  84405,
  84404,
  84403,
  84401,
  84314,
  84315,
  84180,
  84171,
  84170,
  84165,
  84158,
  84157,
  84152,
  84151,
  84147,
  84145,
  84138,
  84133,
  84131,
  84130,
  84129,
  84128,
  84127,
  84125,
  84124,
  84123,
  84122,
  84121,
  84120,
  84119,
  84118,
  84117,
  84116,
  84115,
  84114,
  84113,
  84112,
  84111,
  84110,
  84109,
  84108,
  84107,
  84106,
  84105,
  84104,
  84103,
  84102,
  84101,
  84097,
  84096,
  84095,
  84094,
  84093,
  84092,
  84091,
  84090,
  84089,
  84088,
  84087,
  84084,
  84081,
  84075,
  84070,
  84067,
  84065,
  84059,
  84058,
  84057,
  84056,
  84054,
  84047,
  84045,
  84044,
  84043,
  84042,
  84041,
  84040,
  84037,
  84025,
  84020,
  84016,
  84015,
  84014,
  84013,
  84011,
  84010,
  84009,
  84006,
  84005,
  84004,
  84003,
  84062,
];

const extendedZipCodes = [
  84306,
  84648,
  84645,
  84341,
  84340,
  84339,
  84337,
  84335,
  84332,
  84328,
  84327,
  84326,
  84325,
  84324,
  84323,
  84322,
  84321,
  84319,
  84318,
  84302,
  84098,
  84074,
  84071,
  84060,
  84055,
  84049,
  84036,
  84033,
  84032,
  84029,
];

export { zipCodes, extendedZipCodes };
